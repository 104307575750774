import { create } from "apisauce";

export const ip = "https://service-frontd.com:5500/api/v1/focus";
// export const ip = "https://focus.dplus-frontb.com:2083/api/v1/focus";

//export const ip = 'http://192.168.1.102:4500/api/v1'
//export const ip = 'http://192.168.1.121:4500/api/v1'

const api = create({
  baseURL: ip,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 30000, // 10 seconds
});

const apiupload = create({
  baseURL: ip,
  withCredentials: true,
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "multipart/form-data",
  },
  timeout: 30000, // 10 seconds
});

const apiapp = create({
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "multipart/form-data",
  },
  timeout: 30000, // 10 seconds
});

/**
 * ฟังก์ชั่นสำหรับ ยิงข้อมูล
 * @param {string} path
 * @param {{}} obj
 * @return {{}}
 */
export const POST = (path, obj) =>
  new Promise((resolve, reject) => {
    api
      .post(path, obj)
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

/**
 * ฟังก์ชั่นสำหรับ ดึงข้อมูล
 * @param {string} path
 *
 */
export const GET = (path) =>
  new Promise((resolve, reject) => {
    api
      .get(path)
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

export const UPLOAD = (path, formdata) =>
  new Promise((resolve, reject) => {
    apiupload
      .post(path, formdata)
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

export const CHECK = () => {
  new Promise((resolve, reject) => {
    api
      .post()
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });
};

export const GETAPP = (path) =>
  new Promise((resolve, reject) => {
    apiapp
      .get(path)
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

/* ################################################## URL ################################################## */
export const LOGIN = "/auth/login";
export const RIZZ_GETCLAIM = "/rizz/claim/get";
export const RIZZ_GETREPORT = "/rizz/report/get";
export const RIZZ_INSERT_EMS = "/rizz/claim/insert_ems";
export const RIZZ_GETHISTORY = "/rizz/claim/claim_history";
export const RIZZ_DETAILCLAIMBYID = "/rizz/claim/detailclaimById";
export const RIZZ_UPDATESTATUS = "/rizz/claim/updateStatus";
export const RIZZ_UPSERTCLAIM = "/rizz/claim/updateclaimById";
export const RIZZ_GETUSERALL = "/auth/getUserAll";
export const RIZZ_REGIS_WARRANTY = "/rizz/registor/regiswarranty";
export const RIZZ_GETUSERID = "/rizz/claim/getUserById";
export const RIZZ_EDITCLAIMBYID = "/rizz/claim/editClaim";


