import React, { useEffect } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import App from "./App";
import { Observer } from "mobx-react";
import user from "../mobx/user";
import { menu } from "../resource";
import { RIZZ_GETHISTORY } from "../service";

export default withRouter((props) => {
  useEffect(() => {
    if (user.role) {
      document.title = menu[user.role].title;
    } else {
      document.title = "ระบบจัดการหลังบ้าน";
      // props.history.push('/login')
    }
  }, null);

  if (
    props.location.pathname === "/login" ||
    props.location.pathname === "/rizz/billshipping" ||
    props.location.pathname === "/focus/billshipping"
  )
    return null;

  return (
    <App>
      <Observer>
        {() => (
          <div style={{ padding: 24, background: "#fff", minHeight: 360 }}>
            {user.role &&
              menu[user.role].routes.map((el, i) => {
                return (
                  <Route path={`${el.path}`} component={el.component} key={i} />
                );
              })}

            {user.role && (
              <Route
                exact
                path={"/"}
                component={() => (
                  <Redirect to={`${menu[user.role].defaultUrl}`} />
                )}
              />
            )}
          </div>
        )}
      </Observer>
    </App>
  );
});
