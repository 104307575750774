import { create } from "apisauce";
export const ip = 'https://service-frontd.com:5500/api/v1'
// export const ip = 'http://localhost:3001/api/v1'

//export const ip = "https://focus.dplus-frontb.com:2083/api/v1";
export const ip_dpl = "http://dplus-system.com:9080/focus/api";
//export const ip_dpl = 'http://localhost:9080/focus/api'

export const VERSION = 'VERSION 2.6.3'

const api = create({
  baseURL: ip,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 30000, // 10 seconds
});
const api_dplus = create({
  baseURL: ip_dpl,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 30000, // 10 seconds
});

const apiupload = create({
  baseURL: ip,
  withCredentials: true,
  headers: {
    "Content-Type":
      "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
    Accept: "multipart/form-data",
  },
});

const apiapp = create({
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "multipart/form-data",
  },
});

/**
 * ฟังก์ชั่นสำหรับ ยิงข้อมูล
 * @param {string} path
 * @param {{}} obj
 * @return {{}}
 */
export const POST = (path, obj) =>
  new Promise((resolve, reject) => {
    api
      .post(path, obj)
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });
export const POST_DPL = (path, obj) =>
  new Promise((resolve, reject) => {
    api_dplus
      .post(path, obj)
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

/**
 * ฟังก์ชั่นสำหรับ ดึงข้อมูล
 * @param {string} path
 *
 */
export const GET = (path) =>
  new Promise((resolve, reject) => {
    api
      .get(path)
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

export const UPLOAD = (path, formdata) =>
  new Promise((resolve, reject) => {
    apiupload
      .post(path, formdata)
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

export const CHECK = () => {
  new Promise((resolve, reject) => {
    api
      .post()
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });
};

export const GETAPP = (path) =>
  new Promise((resolve, reject) => {
    apiapp
      .get(path)
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

/* ################################################## URL ################################################## */
export const FOCUS_GETALLCLAIM = "/focus/claim/getAll";
export const FOCUS_EMS = "/focus/ems/focus_ems";
export const FOCUS_UNACTIVE = "/focus/warranty/unactiveWarranty";
export const FOCUS_GETREPORT = "/focus/report/focus_getreport";
export const FOCUS_DETAILCLAIM = "/focus/claim/getClaimById";
export const FOCUS_UPDATECLAIM_STATUS = "/focus/claim/updateClaim_status";
export const FOCUS_EDITCLAIMBYID = "/focus/claim/editClaim";
export const FOCUS_EDITPAYBYID = "/focus/payment/editPay";
export const FOCUS_WARRANTY_ID = "/focus/warranty/getCodegenerateById";
export const FOCUS_WARRANTY_ID_ADMIN = "/focus/warranty/getCodegenerateByIdAdmin";
export const FOCUS_CUSTOMER = "/focus/auth/getUserAll";
export const FOCUS_REGIS_WARRANTY = "/focus/warranty/insertWarranty";
export const FOCUS_REGISTER_SENDNOTI = "/focus/sms/registersend";
export const FOCUS_GETWARRANTYBYTEL = "/focus/warranty/getWarrantyByTel";
export const FOCUS_INSERTCLAIM = "/focus/claim/insertClaim";
export const FOCUS_INSERTADDRESS = "/focus/address/insertAddress";
export const FOCUS_UPDATEADDRESS = "/focus/address/updateAddress";
export const FOCUS_CLAIM_SENDNOTI = "/focus/sms/createclaimsend";
export const FOUCUS_GETHISTORY = "/focus/claim/getHistory";
export const FOCUS_GETPAYMENT = "/focus/payment/getPaymentById";
export const FOCUS_INSERTPAYMENT = "/focus/payment/insertPaymentByAdmin";
export const FOCUS_ACCOUNTINGREPORT = "/focus/report/focus_getaccountingreport";
export const FOCUS_GETDATAIMPORTAX = "/focus/report/focus_getdatatoimportax";
export const FOCUS_UPDATEWARRABTY = "/focus/warranty/updateWarranty";
export const FOCUS_IMPORTAX = "/insertProduct";
export const FOCUS_GETBRAND_CATEGORY = "/focus/product/getBrandByCategory";
export const FOCUS_GETCODEACTIVEBYID =
  "/focus/warranty/getCodegenerateActiveById";
export const FOCUS_GETALLPRODUCT = "/focus/product/getAllProduct";
export const FOUCUS_WARRANTYALL = "/focus/warranty/getAllWarranty";
export const FOCUS_GETCHANNELBUY = "/focus/option/getChannelBuy";
export const FOCUS_GETSTORE = "/focus/store/checkStoreNo/";
export const FOCUS_CHECKTEL = "/focus/auth/checkTelephone/";
export const FOCUS_RESETLINEID = "/focus/auth/resetLineId";
export const FOCUS_CAMPAIGN_GET = "/focus/campaigns";

export const FOCUS_WARRANTY_ACTIVE_ID =
  "/focus/warranty/getCodegenerateActiveById";
export const FOCUS_UPDATE_WARRANTY = "/focus/warranty/updateWarranty";
export const FOCUS_GET_PRODUCTS_WARRANTY = "/focus/product/getAllProduct";
export const FOCUS_UPDATE_PRODUCTS_WARRANTY = "/focus/product/updateProduct";
export const FOCUS_INSERT_PRODUCTS_WARRANTY = "/focus/product/insertProduct";

export const FOCUS_GET_STORE_LIST = "/focus/store/getStoreList";
export const FOCUS_GET_OPTION_TYPESTORE = "/focus/option/getTypeStore";
export const FOCUS_SEARCH_STORE_NO = "/focus/store/checkStoreNo";
export const FOCUS_INSERT_DEALER = "/focus/store/insertStore";
export const FOCUS_EDIT_DEALER = "/focus/store/updateStore";
export const FOCUS_STORE_BY_TYPE = "/focus/store/getStoreByType";
export const FOCUS_GET_OPTION_CHENNEL_CALIM = "/focus/option/getChannelClaim";

export const FOCUS_SEND_MESSAGE = "/focus/system/sendMsgToLine";
export const FOCUS_FIND_CUSTOMER = "/focus/auth/findCustomer ";
export const GET_OPTION_SHIPPING = (shipping_type) =>
  `/focus/option/getShipping/${shipping_type}`;
export const GET_OPTION_BRANCH =
  `/focus/option/getBranchAll`;
export const UPDATE_TRACKING = "/focus/tracking/updateTracking";
export const CREATE_TRACKING = "/focus/tracking/createTracking";
export const GET_COVER_SHEET = "/report/getCoverSheet";


// Customer
export const GET_ALL_CUSTOMER = `/focus/customer/get-all`;
export const UPDATE_CUSTOMER = `/focus/customer/update`;
export const RESET_LINE = `/focus/customer/reset-line`;

export const GET_ADDRESS_BY_ZIPCODE = (zipcode) =>
  `/focus/address/getAddressByZipcode/${zipcode}`;

//QRCODE
export const GET_QR_CODE_WARRANTY = `/focus/qrcode/getQrcode`;
export const GEN_QR_CODE_WARRANTY = `/focus/qrcode/generateQrCode`;
export const DOWNLOAD_QR_CODE_WARRANTY = `/focus/qrcode/downloadQrCode`;


export const ACTIVE_CLAIM__BRANCH = `/focus/claim/activeClaimBranch`;
export const GET_WARRANTY_BY_WARRANTYNO_OR_CLAIMNO = `/focus/warranty/getWarrantyByWarrantyNoOrClaimNo`;
export const APPROVE_WARRANTY_NO = `/focus/warranty/approveWarrantyNo`;