import React, { Component } from "react";
import { observer } from "mobx-react";
import WrapperComponents from "../../../components/hoc/WrapperComponents";
import dayjs from "dayjs";
import moment from "moment";
import { Table, Button as BT, Modal, Input, message, DatePicker } from "antd";
import { columnTableClaimFocus, color, STORE_FOCUS } from "../../../resource";
import autobind from "autobind-decorator";
//import { POST, RIZZ_INSERT_EMS } from '../../../service';
import {
  GET,
  FOCUS_GETALLCLAIM,
  POST,
  FOCUS_EMS,
  FOCUS_UNACTIVE,
  FOCUS_UPDATECLAIM_STATUS,
  FOCUS_SEND_MESSAGE,
  GET_OPTION_SHIPPING,
  UPDATE_TRACKING,
  CREATE_TRACKING,
} from "../../../service_focus";
import { encryptParameter } from "../../../functions/functions";
import { Button } from "../../../components/Button";
import {
  ModalEMS,
  ModalActive,
  ModalSendMessage,
} from "../../../components/Modal";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import ExportJsonExcel from "js-export-excel";
import { ExclamationCircleOutlined } from "@ant-design/icons";

let defaultData = [];

@WrapperComponents()
@observer
class ClaimScene extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRowKeys: [], // Check here to configure the default column
      visibleSendMessage: false,

      loading: false,
      isLoadingData: true,
      visible: false,
      visibleActive: false,
      shipping_date: moment(),
      tracking: "",
      shipping_id: "",
      shipping_name: "",
      fullTextSearch: "",
      shipping_type: null,
      shipping_type_default: null,
      option_shipping: [],
      unactive_code: "",
      dataOption: [],
      data: [],
      searchText: "",
      claim_doc: "",
      claim_list_no: [],
      lineMessage: "",
      isLoadingEMS: false,
      isLoadingActive: false,
      no_warranty: "",
      start_date: moment()
        .startOf("month")
        .format("YYYY-MM-DD"),
      end_date: moment()
        .endOf("month")
        .format("YYYY-MM-DD"),
      isOpen: false,
      user_role:
        !!JSON.parse(localStorage.getItem("DkdL8OVPdp")) &&
        JSON.parse(localStorage.getItem("DkdL8OVPdp")).role,
    };
  }

  componentDidMount() {
    // this.getOptionShipping();
    this.onFetchClaimFocus();
  }

  getOptionShipping = async () => {
    const { shipping_type } = this.state;
    try {
      const res = await GET(GET_OPTION_SHIPPING(shipping_type));
      const { success, result } = res;
      if (success) {
        const option = result.map((el) => {
          return {
            value: `${el.shipping_id}|${el.shipping_name}`,
            label: el.shipping_name,
          };
        });

        this.setState({
          option_shipping: option,
          dataOption: result,
          shipping_id: "",
          shipping_name: "",
        });
      }
    } catch (error) {
      Modal.error({ title: error.message });
    }
  };
  @autobind
  async onFetchClaimFocus(
) {
    const { user_role, fullTextSearch } = this.state;

    // const obj = {
    //   date_start: !fullTextSearch ? this.state.start_date : "",
    //   date_end: !fullTextSearch ? this.state.end_date : "",
    //   text_search: fullTextSearch || "",
    //   role: user_role,
    // }

    try {
      const res = await GET(
        `${FOCUS_GETALLCLAIM}?date_start=${!fullTextSearch ? this.state.start_date : ""}&date_end=${!fullTextSearch ? this.state.end_date : ""}&role=${user_role}&text_search=${fullTextSearch || ""}`
      );
      const start = moment(new Date());

      if (res.success) {
        const data = res.result.map((el, i) => {
          const end = moment(el.dateexp);
          const diff = end.diff(start, "days");

          return {
            ...el,
            no: i + 1,
            key: el.no_claim,
            time_warranty: diff <= 0 ? 0 : diff,
            dateclaim_formate: moment(el.dateclaim).format("DD/MM/YYYY"),
            shipping_formate: el.shipping_date
              ? dayjs(el.shipping_date).format("DD/MM/YYYY")
              : "",
            confirm_ems:
              el.status === 3 && (el.tracking === null || el.tracking === "")
                ? "กรอกเลขพัสดุ"
                : el.tracking,
            unactive:
              (el.status === 3 || el.status === 4) &&
              (el.unactive_code === null || el.unactive_code === "")
                ? "รหัสประกัน"
                : el.unactive_code,
            register_type: el.type,
            shipping_id: el.shipping_id,
            shipping_name: el.shipping_name,
            shipping_type: el.shipping_type,
          };
        });


        console.log({data});
        defaultData = data;

        this.setState({
          data,
          isLoadingData: false,
          visible: false,
          tracking: "",
          isLoadingEMS: false,
          isLoadingActive: false,
          visibleActive: false,
        });

        // if (searchText) {
        //   this.onSearch(searchText);
        // }
      }
    } catch (error) {}
  }
  @autobind
  downloadExcel() {
    const data_excel = this.state.data;
    let option = {};
    let datatable = [];
    let date_export = dayjs(new Date()).format("DD-MM-YYYY");

    if (data_excel) {
      data_excel.map((el) => {
        const {
          no_claim,
          name,
          tel,
          dateclaim,
          detail,
          brandmobile,
          modelmobile,
          nameproduct,
          time_warranty,
          status_name,
          confirm_ems,
          shipping_date,
          unactive,
          type,
          address,
        } = el;
        const findIndex = STORE_FOCUS.findIndex(
          (el) => el.original_name === type
        );

        let obj = {
          no_claim,
          name,
          tel,
          dateclaim: moment(dateclaim).format("DD-MM-YYYY HH:mm:ss"),
          brandmobile,
          modelmobile,
          nameproduct,
          detail,
          time_warranty,
          status_name,
          confirm_ems,
          shipping_date,
          unactive,
          type: STORE_FOCUS[findIndex].thai_name,
          address,
        };
        datatable.push(obj);
      });
    }
    option.fileName = `รายงานลงทะเบียนรับประกันสินค้า ${date_export}`;
    option.datas = [
      {
        sheetData: datatable,
        sheetName: date_export,

        sheetHeader: [
          "เลขที่เคลม",
          "ชื่อ-นามสกุล",
          "เบอร์โทร",
          "วันที่เคลม",
          "ยี่ห้อ",
          "รุ่น",
          "ชื่อสินค้า",
          "สาเหตุการเคลม",
          "วันคงเหลือ",
          "สถานะ",
          "เลขพัสดุ",
          "วันที่จัดส่ง",
          "QR Code",
          "ประเภทเคลม",
          "ที่อยู่",
        ],
      },
    ];

    const toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  start = () => {
    let claim_doc = this.state.selectedRowKeys;
    this.setState({ loading: true });

    setTimeout(() => {
      this.setState(
        {
          selectedRowKeys: [],
          loading: false,
        },
        () =>
          this.props.history.push({
            pathname: "/focus/billshipping",
            state: {
              id: encryptParameter({ claim_id: claim_doc }),
            },
          })
      );
    }, 1000);
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  /**
   * ฟังก์ชั่นสำหรับวิ่งไปหน้า Detail Claim
   */
  @autobind
  goDetail(record) {
    this.props.history.push(`/focus/claim_detail?id=${record.no_claim}`);
  }
  @autobind
  goCreate() {
    this.props.history.push("/focus/claim_create");
  }

  @autobind
  insertEMS(record, type) {
    //const { dataOption } = this.state;
    console.log({record})
    const {
      no_claim,
      no_warranty,
      tracking,
      shipping_date,
      shipping_id,
      shipping_name,
      user_id,
      tel,
      shipping_type,
    } = record;


    if (type === 0) {
      this.setState({
        visible: true,
        claim_doc: no_claim,
        claim_list_no: [no_claim],
        type,
        no_warranty,
        shipping_id,
        shipping_name,
        tel,
        shipping_type: null,
        shipping_type_default: null
      });
    } else {
      this.setState({
        visible: true,
        claim_doc: no_claim,
        claim_list_no: [no_claim],
        type,
        shipping_id,
        shipping_name,
        shipping_date: shipping_date ? moment(shipping_date) : moment(),
        tracking: tracking,
        no_warranty,
        user_id,
        tel,
        shipping_type,
        shipping_type_default: null
      });
    }
  }
  @autobind
  insertUnactiveCode(record, type) {
    const { no_claim, no_warranty, unactive_code } = record;

    if (type === 0) {
      this.setState({
        visibleActive: true,
        claim_doc: no_claim,
        type,
        no_warranty,
      });
    } else {
      this.setState({
        visibleActive: true,
        claim_doc: no_claim,
        type,
        unactive_code,
        no_warranty,
      });
    }
  }

  @autobind
  handleCancel() {
   
    this.setState({
      visible: false,
      claim_doc: "",
      type: 0,
      shipping_date: moment(),
      ems: "",
      option_shipping: []
    })


  }
  @autobind
  handleCancel_active() {
    this.setState({ visibleActive: false, type: 0, unactive_code: "" });
  }

  @autobind
  async handleOk() {
    const {
      tracking,
      shipping_date,
      no_warranty,
      shipping_id,
      shipping_type,
      shipping_name,
      claim_list_no
    } = this.state;


    if (
      !shipping_type ||
      (shipping_type === "SELF" && (!tracking || !shipping_name))
    ) {
      message.error("กรุณาใส่ข้อมูลให้ครบ");
    } else if (
      !shipping_type ||
      (shipping_type === "SYSTEM" && !shipping_name)
    ) {
      message.error("กรุณาใส่ข้อมูลให้ครบ");
    } else {
      this.setState({ isLoadingEMS: true }, async () => {
        try {
          let obj = {
            shipping_id,
            shipping_date,
            shipping_type,
          };



          if (shipping_type === "SYSTEM") {
               
                  const res_tracking = await POST(CREATE_TRACKING, { ...obj, no_claim: claim_list_no });
                  // if (res_tracking.success) {
                  //   const res = await POST(UPDATE_TRACKING, {
                  //                           status: "TRANSPORTED",
                  //                           tracking: res_tracking.result.tracking,
                  //                           no_warranty,
                  //                           sorting_code: res_tracking.result.sorting_code,
                  //                           ...obj,
                  //                         })

                  //   if (res.success) {
                  //     Modal.success({ title: res.result.message });
                  //     this.onFetchClaimFocus();
                  //   }

                  // }

                    if (res_tracking.success) {
                      Modal.success({ title: res_tracking.result.message });
                      this.onFetchClaimFocus();
                    }


            } else {
              obj = { ...obj, status: "TRANSPORTED", tracking, no_warranty };
              let res = await POST(UPDATE_TRACKING, obj);
              if (res.success) {
                Modal.success({ title: res.result.message });
                this.onFetchClaimFocus();
              }
            }
        } catch (error) {
          this.setState({ visible: false, ems: "", isLoadingEMS: false });
          Modal.error({ title: error.message });
        }
      });
    }
  }

  // @autobind
  // async handleOk() {
  //   const { ems, shipping_date, no_warranty, user_id, tel } = this.state;
  //   if (!ems) {
  //     message.error("กรุณาใส่ข้อมูลให้ครบ");
  //     return;
  //   } else {
  //     this.setState({ isLoadingEMS: true }, async () => {
  //       try {
  //         let res = await POST(FOCUS_EMS, {
  //           status: "TRANSPORTED",
  //           ems,
  //           shipping_date,
  //           no_warranty,
  //           user_id,
  //           telephone: tel,
  //         });

  //         // await POST(FOCUS_UPDATECLAIM_STATUS, { no_warranty, status: 'TRANSPOTED', ems_tracting: ems, user_id, reject_detail: '' })
  //         if (res.success) {
  //           this.onFetchClaimFocus();
  //         }
  //       } catch (error) {
  //         this.setState({ visible: false, ems: "", isLoadingEMS: false });
  //       }
  //     });
  //   }
  // }
  @autobind
  async handleOk_active() {
    const { unactive_code, no_warranty } = this.state;

    if (!unactive_code) {
      message.error("กรุณาใส่ข้อมูลให้ครบ");
      return;
    } else {
      this.setState({ isLoadingActive: true }, async () => {
        try {
          const res = await POST(FOCUS_UNACTIVE, {
            unactive_code,
            no_warranty,
          });

          if (res.result === "success") {
            this.onFetchClaimFocus();
          } else {
            message.error("เลขรับประกันไม่ถูกต้องหรืออาจถูกใช้งานไปแล้ว");
            this.setState({ isLoadingActive: false });
          }
        } catch (error) {
          this.setState({
            visibleActive: false,
            unactive_code: "",
            isLoadingActive: false,
          });
        }
      });
    }
  }

  @autobind
  onChange(e, ref) {
    if (ref === "shipping") {
      const id = e.split("|")[0];
      const name = e.split("|")[1];
      this.setState({ shipping_id: id, shipping_name: name });
    }else if (ref === "fullTextSearch") {
      this.setState({ fullTextSearch: e });
    }
    else {
      this.setState({ [ref]: e }, () =>
        ref === "shipping_type" ? this.getOptionShipping() : ""
      )
    }
  }

  @autobind
  onKeyPressSearch(event,type) {
    if (event.key === "Enter") {
      if (type === "fullTextSearch") {
        this.setState({ fullTextSearch: event.target.value });
        this.onFetchClaimFocus();
      } else{
        this.onSearch();
      }
    }
  }

  @autobind
  onSearch() {
    const { searchText } = this.state;
    if (!searchText) {
      return this.setState({ data: defaultData });
    }

    const d = defaultData.filter((el) => {
      return Object.values(el).some((val) => {
        return val
          ? val
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          : false;
      });
    });

    this.setState({ data: d });
  }
  @autobind
  onChangeRangePicker(dates) {
    console.log()
   
    const start = moment(dates[0]).format("YYYY-MM-DD");
    const end = moment(dates[1]).format("YYYY-MM-DD");
    console.log({dates})
    this.setState({
      selectedRowKeys: [],
      start_date: start,
      end_date: end,
      isLoadingData: true,
    },()=>this.onFetchClaimFocus(start, end));
    
  }
  @autobind
  clickImage(src_image) {

    this.setState({
      zoomImage: src_image,
      isOpen: true,
    });
  }
  @autobind
  async approveClaim() {
    const { data, selectedRowKeys } = this.state;
    let result = [];
    let notcolect = [];
    result = selectedRowKeys.map((sl) => {
      let r = data.filter((el) => el.no_claim === sl);
      if (r[0]) {
        return {
          no_claim: r[0].no_claim,
          no_warranty: r[0].no_warranty,
          user_id: r[0].user_id,
          status: r[0].status,
        };
      }
    });

    result.map((el) => {
      if (el.status !== 2) {
        notcolect.push(el.no_claim);
      }
    });
    if (notcolect.length > 0) {
      message.error(`สถานะงานเคลม :${notcolect[0]} ไม่สามารถอนุมัติได้`);
    } else {
      this.setState({ isLoadingData: true });

      console.log({result})
      try {
        let res;
        await Promise.all(
          result.map(async (el) => {
    
            res = await POST(FOCUS_UPDATECLAIM_STATUS, {
              no_claim:el.no_claim,
              no_warranty: el.no_warranty,
              status: "USED",
              user_id: el.user_id,
              ems_tracting: "",
              reject_detail: "",
            });
          })
        );
        if (res.success) {
          this.onFetchClaimFocus();
        }
      } catch (error) {
        message.error("ระบบขัดคล่องกรุณาลองใหม่ภายหลัง");
      }
    }
  }

  createMessage = (record) => {
    this.setState({
      visibleSendMessage: true,
      user_id: record.user_id,
      no_claim: record.no_claim
      // lineMessage: `เลขที่เคลม: ${record.no_claim} | `
    });
  };

  @autobind
  async handleSendMessage() {
    const { lineMessage, user_id, no_claim } = this.state
    const msg =  `เลขที่เคลม: ${no_claim} \n\n${lineMessage}` 
    const obj = { 
      user_id, 
      msg
    }

    try {
      let res = await POST(FOCUS_SEND_MESSAGE, obj);
      if (res.success) {
        Modal.success({
          title: res.result,
        });

        this.setState(
          {
            visibleSendMessage: false,
            claim_doc: "",
            lineMessage: "",
            user_id: "",
          }
        );
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || "",
      });
    }
  }

  openConfirm = (messages) => {
    const { lineMessage } = this.state;
    if (!lineMessage) {
      return Modal.error("กรุณากรอกข้อความ");
    } else {
      Modal.confirm({
        title: messages.title,
        icon: <ExclamationCircleOutlined />,
        content: messages.content || "",
        onOk: this.handleSendMessage,
        okText: "ยืนยัน",
        cancelText: "ยกเลิก",
      });
    }
  };

  render() {
    const {
      loading,
      selectedRowKeys,
      data,
      isLoadingData,
      shipping_date,
      tracking,
      searchText,
      unactive_code,
      isOpen,
      visibleSendMessage,
      lineMessage,
      claim_doc,
      option_shipping,
      shipping_type,
      shipping_id,
      shipping_name,
      shipping_type_default,
      fullTextSearch

    } = this.state;



    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const chooseShipping =  ()  => {
      const { data, selectedRowKeys } = this.state;

    setTimeout(() => {
        this.setState(
          {
            shipping_type: 'SYSTEM',
          },
          () => {
            this.getOptionShipping()

            const selected = selectedRowKeys.map((sl) => {
              let r = data.find((el) => el.no_claim === sl)
                   const { no_warranty, no_claim, status, user_id } = r
                    return {
                      no_warranty,
                      no_claim,
                      status,
                      user_id
                    }
            });
      
            let claimNotInShip = []
            for (const claim of selected) {
                if(claim.status !== 3){
                  claimNotInShip.push(claim)
                }
            }

            if(claimNotInShip.length > 0){
              message.error(`มีรายการเคลมไม่อยู่ในสถานะเลือกขนส่งได้ ${claimNotInShip.length} รายการ: ${claimNotInShip.map( e => { return e.no_claim })}`);
              return
            }
           
            let claim_list_no = selected.map(e => { return e.no_claim })
           
            this.setState({
              visible: true,
              claim_list_no: claim_list_no,
              shipping_type_default: "SYSTEM"
            })

          }
           
        );
      }, 1);
      
    }


    const hasSelected = selectedRowKeys.length > 0;
    return (
      <div>
        {/*###################### MODAL EMS ######################*/}
        <Modal
          title={<p style={{ textAlign: "center" }}></p>}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width={"30vw"}
          confirmLoading={this.state.isLoadingEMS}
          cancelText={<font style={{ fontSize: "1vw" }}>ยกเลิก</font>}
          okText={<font style={{ fontSize: "1vw" }}>ยืนยัน</font>}
          okButtonProps={{
            style: {
              backgroundColor: color.rizz_primary,
              color: "#fff",
              borderColor: color.rizz_primary,
            },
          }}
        >
          <ModalEMS
            obj={{
              shipping_date,
              tracking,
              option_shipping,
              shipping_type,
              shipping_id,
              shipping_name,
              shipping_type_default
            }}
            onChange={(e, ref) => this.onChange(e, ref)}
            onClose={(e) => this.onClose(e)}
          />
        </Modal>

     
        {/*###################### MODAL SEND MESSAGE ######################*/}
        <Modal
          // title="ข้อความ"
          visible={visibleSendMessage}
          onOk={() => this.openConfirm({ title: "ยืนยันส่งข้อความ" })}
          onCancel={() =>
            this.setState({
              visibleSendMessage: false,
              claim_doc: "",
              user_id: "",
              lineMessage: ""
            })
          }
          width={450}
          confirmLoading={this.state.isLoadingEMS}
          cancelText={<font style={{ fontSize: "1vw" }}>ยกเลิก</font>}
          okText={<font style={{ fontSize: "1vw" }}>ยืนยัน</font>}
          okButtonProps={{
            style: {
              backgroundColor: color.focus_complete,
              color: "#fff",
              borderColor: color.focus_complete,
            },
          }}
        >


        <ModalSendMessage
            onChange={(e, ref) => this.onChange(e, ref)}
            data={{ lineMessage }}
          />
        </Modal>


        {/*###################### MODAL Activate ######################*/}
        <Modal
          title={
            <p style={{ textAlign: "center" }}>
              กรุณาใส่เลขรหัสที่ต้องการยกเลิก
            </p>
          }
          visible={this.state.visibleActive}
          onOk={this.handleOk_active}
          onCancel={this.handleCancel_active}
          width={"30vw"}
          confirmLoading={this.state.isLoadingActive}
          cancelText={<font style={{ fontSize: "1vw" }}>ยกเลิก</font>}
          okText={<font style={{ fontSize: "1vw" }}>ยืนยัน</font>}
          okButtonProps={{
            style: {
              backgroundColor: color.rizz_primary,
              color: "#fff",
              borderColor: color.rizz_primary,
            },
          }}
        >
          <ModalActive
            obj={{ unactive_code }}
            onChange={(e, ref) => this.onChange(e, ref)}
          />
        </Modal>
        {isOpen && (
          <Lightbox
            mainSrc={this.state.zoomImage}
            onCloseRequest={() => this.setState({ isOpen: false })}
          />
        )}

        <div className={"column"}>
          {/*###################### HEAD ######################*/}
          <div className={"row-between"}>
            <div style={{ display: "flex" }}>
              <BT
                type="primary"
                onClick={this.start}
                disabled={!hasSelected}
                loading={loading}
              >
                บิลใบส่งสินค้า
              </BT>
              <BT
                type="primary"
                style={{
                  backgroundColor: color.dplus_primary,
                  borderColor: color.dplus_primary,
                }}
                onClick={this.downloadExcel}
                loading={loading}
              >
                Export excel
              </BT>
              <span style={{ marginLeft: 8 }}>
                {hasSelected ? `เลือก ${selectedRowKeys.length} รายการ` : ""}
              </span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Input.Search
                style={{
                  width: "20vw",
                  borderRadius: "10vw",
                  paddingLeft: "1vw",
                }}
                placeholder={"ค้นหาข้อมูล"}
                value={searchText}
                onKeyPress={(e) => this.onKeyPressSearch(e,"searchText")}
                onSearch={(value) => this.onSearch()}
                onChange={(e) => this.onChange(e.target.value, "searchText")}
              />
              {/* <Button title={'ยื่นเรื่องเคลม'} type={'focus'} onPress={() => this.goCreate()} /> */}
              <BT
                type="primary"
                style={{
                  backgroundColor: color.dplus_primary,
                  borderColor: color.dplus_primary,
                }}
                onClick={this.goCreate}
              >
                ยื่นเรื่องเคลม
              </BT>
            </div>
          </div>
          <div className={"row-between"}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                alignItems: "center",
              }}
            >
              <DatePicker.RangePicker
                //value={moment(new Date())}
                placeholder={["วันที่เริ่มต้น", "วันที่สิ้นสุด"]}
                format="YYYY-MM-DD"
                defaultValue={[
                  moment(this.state.start_date),
                  moment(this.state.end_date),
                ]}
                onChange={this.onChangeRangePicker}
                size="default"
              />

              {/* <Button title={'ยืนยัน'} type={'focus'} onPress={() => this.goCreate()} /> */}
            </div>
          </div>
          <div className={"row-between"}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                alignItems: "center",
              }}
            >
              <span style={{ marginRight: 8 }}>
                {hasSelected ? `เลือก ${selectedRowKeys.length} รายการ` : ""}
              </span>
              <BT
                type="primary"
                style={{
                  backgroundColor: "#faad14",
                  borderColor: "#faad14",
                  margin: '5px'
                }}
                onClick={chooseShipping}
                disabled={!hasSelected}
                loading={loading}
              >
                เลือกขนส่ง
              </BT>
              <BT
                type="primary"
                style={{
                  backgroundColor: color.dplus_primary,
                  borderColor: color.dplus_primary,
                }}
                onClick={this.approveClaim}
                disabled={!hasSelected}
                loading={loading}
              >
                อนุมัติเรื่องเคลม
              </BT>
          
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Input.Search
              style={{
                width: "20vw",
                borderRadius: "10vw",
                paddingLeft: "1vw",
              }}
              placeholder={"ค้นหาข้อมูลทั้งหมดโดยไม่อิงตามวันที่"}
              value={fullTextSearch}
              onKeyPress={(e) => this.onKeyPressSearch(e, "fullTextSearch")}
              onChange={(e) => this.onChange(e.target.value, "fullTextSearch")}
            />
          </div>
          <div className="text-subtitle">
            <font>
              {
                "(* ค้นหาได้เฉพาะ เลขที่เคลม , ชื่อนามสกุล, ชื่อไลน์, และเบอร์โทรศัพท์)"
              }
            </font>
          </div>

          {/*###################### TABLE ######################*/}

          <Table
            title={() => <h2>การเคลมสินค้า</h2>}
            expandedRowRender={(record) => this.renderExpand(record)}
            rowSelection={rowSelection}
            columns={columnTableClaimFocus(
              (record) => this.goDetail(record),
              (record, type) => this.insertEMS(record, type),
              (record) => this.createMessage(record),
              (record, type) => this.insertUnactiveCode(record, type)
            )}
            rowClassName={(record, i) => (i % 2 === 1 ? "even" : "")}
            dataSource={data}
            bordered
            pagination={{ pageSize: 10 }}
            loading={isLoadingData}
            size="small"
            scroll={{ x: true }}
          />
        </div>
      </div>
    );
  }
  renderExpand(record) {
    return (
      <div style={{ padding: "1vw", display: "flex", alignItems: "center" }}>
        <div>
          <img
            src={record.picclaim}
            style={{ width: 100, height: 100 }}
            onClick={() => this.clickImage(record.picclaim)}
            className={"contain"}
          />
        </div>

        <div style={{ marginLeft: "1vw" }}>
          <div style={{ display: "flex", marginBottom: "-12px" }}>
            <p style={{ margin: 0, width: "100px" }}>ชื่อสินค้า</p>
            <p>{record.nameproduct}</p>
          </div>
          <div style={{ display: "flex", marginBottom: "-12px" }}>
            <p style={{ margin: 0, width: "100px" }}>รุ่นมือถือ</p>
            <p>
              {record.brandmobile}` `{record.modelmobile}
            </p>
          </div>
          <div style={{ display: "flex", marginBottom: "-12px" }}>
            <p style={{ margin: 0, width: "100px" }}>อาการเสีย</p>
            <p>{record.detail}</p>
          </div>
        </div>
        <div>
          {/* <img src={record.bill_pay} style={{ width: 100, height: 100 }} className={'contain'} /> */}
          {record.bill_pay ? (
            <img
              src={record.bill_pay}
              style={{ width: 100, height: 100 }}
              onClick={() => this.clickImage(record.bill_pay)}
              className={"contain"}
            />
          ) : (
            <div></div>
          )}
        </div>

        <div style={{ marginLeft: "1vw" }}>
          <div style={{ display: "flex", marginBottom: "-12px" }}>
            {/* <p style={{ margin: 0, width: '100px' }} >วันที่ชำระ</p> */}
            {record.date_pay ? (
              <p style={{ margin: 0, width: "100px" }}>วันที่ชำระ</p>
            ) : (
              <p></p>
            )}
            {record.date_pay ? <p>{record.date_pay}</p> : <p></p>}
          </div>
          <div style={{ display: "flex", marginBottom: "-12px" }}>
            {/* <p style={{ margin: 0, width: '100px' }} >เวลาชำระ</p> */}
            {/* <p>{record.time_pay}</p> */}
            {record.time_pay ? (
              <p style={{ margin: 0, width: "100px" }}>เวลาชำระ</p>
            ) : (
              <p></p>
            )}
            {record.time_pay ? <p>{record.time_pay}</p> : <p></p>}
          </div>
          <div style={{ display: "flex", marginBottom: "-12px" }}>
            {/* <p style={{ margin: 0, width: '100px' }} >ยอดเงินที่ชำระ</p> */}
            {/* <p>{record.money_pay}</p> */}
            {record.money_pay ? (
              <p style={{ margin: 0, width: "100px" }}>ยอดเงินที่ชำระ</p>
            ) : (
              <p></p>
            )}
            {record.money_pay ? <p>{record.money_pay}</p> : <p></p>}
          </div>
        </div>
      </div>
    );
  }
}

export default ClaimScene;
