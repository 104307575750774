import React, { Component } from "react";
import { observer } from "mobx-react";
import { Table, Input, DatePicker, Button as BT, Modal } from "antd";
import { FocuscolumnTableWarranty, color } from "../../../resource";
import autobind from "autobind-decorator";
import moment from "moment";
import { POST, FOUCUS_WARRANTYALL, FOCUS_SEND_MESSAGE, APPROVE_WARRANTY_NO } from "../../../service_focus";
import dayjs from "dayjs";
import "react-image-lightbox/style.css";

import ExportJsonExcel from "js-export-excel";
import { ModalSendMessage } from "../../../components/Modal";
import { ExclamationCircleOutlined } from "@ant-design/icons";
let defaultData = [];

@observer
class WarrantyScene extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      isLoadingData: true,
      searchText: "",
      start_date: moment()
        .startOf("month")
        .format("YYYY-MM-DD"),
      end_date: moment()
        .endOf("month")
        .format("YYYY-MM-DD"),
      fullTextSearch: "",
      user_role:
        !!JSON.parse(localStorage.getItem("DkdL8OVPdp")) &&
        JSON.parse(localStorage.getItem("DkdL8OVPdp")).role,

      filters: {},
      visibleSendMessage: false,
      lineMessage: "",
      // user_id: ""
    };
  }

  componentDidMount() {
    this.onFetchWarranty(this.state.start_date, this.state.end_date, "");
  }

  @autobind
  async onFetchWarranty(
    date_start = this.state.start_date,
    date_end = this.state.end_date,
    fullSearch
  ) {
    const { user_role } = this.state;
    try {
      this.setState({ isLoadingData: true })
      const start = moment(new Date());

      let obj = {
        date_start,
        date_end,
        fullTextSearch: fullSearch === "" ? "" : fullSearch,
        role: user_role,
      };
      const res = await POST(FOUCUS_WARRANTYALL, obj);
      let data = [];
      if (res.success) {
        data = res.result.map((el, i) => {
          const {
            no_warranty,
            // name,
            // nameproduct,
            // brandmobile,
            // modelmobile,
            status,
            // no_claim,
            // detail,
            // dateclaim,
            // address,
            // note,
            // tracking,
            // unactive_code,
            dateexp,
            // decode,
            // namestore,
          } = el;
          const end = moment(dateexp);
          const diff = end.diff(start, "days");
          return {
            no: i + 1,
            key: no_warranty,
            ...el,
            telephone: el.tel,
            create: dayjs(el.datenow).format("DD/MM/YYYY"),
            status: this.convertStatus(status),
            status_name: status,
            dateexp: dayjs(dateexp).format("DD/MM/YYYY"),
            time_warranty: diff <= 0 ? 0 : diff,
          };
        });
      }
      defaultData = data;
      this.setState({ isLoadingData: false, data, excel_data: res.result });


      let channelBuy = []
      let products = []
      let modelMobiles = []
      for (const obj of defaultData) {
        if (!channelBuy.some(e => e.text === obj.namestore)) {
          channelBuy.push({ text: obj.namestore, value: obj.namestore })
        }

        if (!products.some(e => e.text === obj.nameproduct)) {
          if (!!obj.nameproduct) {
            products.push({ text: obj.nameproduct, value: obj.nameproduct })
          }
        }

        if (!modelMobiles.some(e => e.text === obj.modelmobile)) {
          modelMobiles.push({ text: obj.modelmobile, value: obj.modelmobile })
        }

      }

      this.setState({
        filters: {
          channelBuy,
          products,
          modelMobiles
        }
      })


    } catch (error) {
      console.log(error);
    }
    finally {
      this.setState({ isLoadingData: false })
    }
  }

  @autobind
  convertStatus(status) {
    switch (status) {
      case "PAYMENT":
        return 1;
      case "WAIT":
        return 2;
      case "USED":
        return 3;
      case "TRANSPORTED":
        return 4;
      case "REJECT":
        return 99;
      default:
        return 0;
    }
  }

  @autobind
  onChange(e, ref) {
    this.setState({ [ref]: e });
  }

  @autobind
  onKeyPressSearch(event, type) {
    if (event.key === "Enter") {
      if (type === "fullTextSearch") {
        this.onFetchWarranty("", "", event.target.value);
      } else if (type === "searchText") {
        this.onSearch();
      }
    }
  }

  @autobind
  onSearch() {
    const { searchText } = this.state;
    if (!searchText) {
      return this.setState({ data: defaultData });
    }

    const d = defaultData.filter((el) => {
      return Object.values(el).some((val) => {
        return val
          ? val
            .toString()
            .toLowerCase()
            .includes(searchText.toLowerCase())
          : false;
      });
    });

    this.setState({ data: d });
  }
  @autobind
  onChangeRangePicker(dates) {
    let start = moment(dates[0]).format("YYYY-MM-DD");
    let end = moment(dates[1]).format("YYYY-MM-DD");
    this.setState({
      start_date: start,
      end_date: end,
      isLoadingData: true,
    });
    this.onFetchWarranty(start, end, "");
  }
  @autobind
  downloadExcel() {
    const data_excel = this.state.data;
    let option = {};
    let datatable = [];
    let date_export = dayjs(new Date()).format("DD-MM-YYYY");

    let sheetHeader = [
      "no",
      "key",
      "no_warranty",
      "qrcode",
      "category",
      "status_name",
      "brandmobile",
      "modelmobile",
      "nameproduct",
      "namestore",
      "name",
      "tel",
      "email",
      "datenow",
      "no_claim",
      "detail",
      "dateclaim",
      "address",
      "note",
      "tracking",
      "unactive_code",
    ];

    sheetHeader.push(Object.keys(data_excel[0]));
    if (data_excel) {
      data_excel.map((el, i) => {
        const {
          no,
          key,
          no_warranty,
          qrcode,
          category,
          status_name,
          brandmobile,
          modelmobile,
          nameproduct,
          name,
          tel,
          email,
          datenow,
          no_claim,
          detail,
          dateclaim,
          address,
          note,
          tracking,
          unactive_code,
          decode,
          namestore,
        } = el;

        let obj = {
          no,
          key,
          no_warranty,
          decode,
          category,
          status_name,
          brandmobile,
          modelmobile,
          nameproduct,
          namestore,
          name,
          tel,
          email,
          datenow: moment(datenow).format("DD-MM-YYYY HH:mm:ss"),
          no_claim,
          detail,
          dateclaim: moment(dateclaim).format("DD-MM-YYYY HH:mm:ss"),
          address,
          note,
          tracking,
          unactive_code,
        };
        datatable.push(obj);
      });

    }
    option.fileName = `รายงานลงทะเบียนรับประกันสินค้า ${date_export}`;
    option.datas = [
      {
        sheetData: datatable,
        sheetName: date_export,

        sheetHeader: sheetHeader,
      },
    ];

    const toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }
  @autobind
  goDetail(record) {
    console.log("record", record.no_warranty);
    window.open(`/focus/warranty_detail?id=${record.no_warranty}`, "_blank");
  }

  @autobind
  async handleSendMessage() {
    const { lineMessage, user_id, no_warranty } = this.state;
    const msg = `เลขที่ประกัน: ${no_warranty} \n\n${lineMessage}`
    const obj = { user_id, msg };
    try {
      let res = await POST(FOCUS_SEND_MESSAGE, obj);
      if (res.success) {
        Modal.success({
          title: res.result,
        });

        this.setState(
          {
            visibleSendMessage: false,
            claim_doc: "",
            lineMessage: "",
            user_id: "",
            //searchText: "",
          }
          // () => this.onSearch()
        );
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || "",
      });
    }
  }

  createMessage = (record) => {
    this.setState({
      visibleSendMessage: true,
      user_id: record.userId,
      no_warranty: record.no_warranty
      // lineMessage: `เลขที่ประกัน: ${record.no_warranty} | `
    });
  }

  openConfirm = (messages) => {
    const { lineMessage } = this.state;
    if (!lineMessage) {
      return Modal.error("กรุณากรอกข้อความ");
    } else {
      Modal.confirm({
        title: messages.title,
        icon: <ExclamationCircleOutlined />,
        content: messages.content || "",
        onOk: this.handleSendMessage,
        okText: "ยืนยัน",
        cancelText: "ยกเลิก",
      });
    }
  };

  confirmAppoveWarranty = (warrantyNo) => {
    Modal.confirm({
      title: "ยืนยันตัดรับประกันสินค้า",
      icon: <ExclamationCircleOutlined />,
      content: `เลขที่ลงทะเบียน ${warrantyNo}`,
      onOk: () => this.appoveWarranty(warrantyNo),
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
    });

  }

  appoveWarranty = async (warrantyNo) => {
    try {
      const { fullTextSearch } = this.state
      const res = await POST(APPROVE_WARRANTY_NO, { warrantyNo })
      const { success, result } = res
      const { start_date, end_date } = this.state
      if (success) {
        Modal.success({ title: `${result.message}` })
        this.onFetchWarranty(start_date, end_date,fullTextSearch || '')
      }
    } catch (err) {
      Modal.error(`เกิดข้อผิดพลาด (${err.message})`);
    }

  }


  render() {
    const {
      data,
      isLoadingData,
      searchText,
      loading,
      fullTextSearch,
      filters,
      visibleSendMessage,
      lineMessage
    } = this.state;

    return (
      <div>

        {/*###################### MODAL SEND MESSAGE ######################*/}
        <Modal
          // title={`เลขที่ประกัน ${}`}
          visible={visibleSendMessage}
          onOk={() => this.openConfirm({ title: "ยืนยันส่งข้อความ" })}
          onCancel={() =>
            this.setState({
              visibleSendMessage: false,
              claim_doc: "",
              user_id: "",
              lineMessage: "",
            })
          }
          width={450}
          confirmLoading={this.state.isLoadingEMS}
          cancelText={<font style={{ fontSize: "1vw" }}>ยกเลิก</font>}
          okText={<font style={{ fontSize: "1vw" }}>ยืนยัน</font>}
          okButtonProps={{
            style: {
              backgroundColor: color.focus_complete,
              color: "#fff",
              borderColor: color.focus_complete,
            },
          }}
        >
          <ModalSendMessage
            onChange={(e, ref) => this.onChange(e, ref)}
            data={{ lineMessage }}
          />
        </Modal>


        <div className={"column"}>
          {/*###################### HEAD ######################*/}

          <div className={"row-between"}>
            <BT
              type="primary"
              style={{
                backgroundColor: color.dplus_primary,
                borderColor: color.dplus_primary,
              }}
              onClick={this.downloadExcel}
              loading={loading}
            >
              Export excel
            </BT>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Input.Search
                style={{
                  width: "20vw",
                  borderRadius: "10vw",
                  paddingLeft: "1vw",
                }}
                placeholder={"ค้นหาข้อมูลตามวันที่"}
                value={searchText}
                onKeyPress={(e) => this.onKeyPressSearch(e, "searchText")}
                onSearch={() => this.onSearch()}
                onChange={(e) => this.onChange(e.target.value, "searchText")}
              />
            </div>
          </div>
          <div className={"row-between"}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                alignItems: "center",
              }}
            >
              <DatePicker.RangePicker
                //value={moment(new Date())}
                placeholder={["วันที่เริ่มต้น", "วันที่สิ้นสุด"]}
                format="YYYY-MM-DD"
                defaultValue={[
                  moment(this.state.start_date),
                  moment(this.state.end_date),
                ]}
                onChange={this.onChangeRangePicker}
                size="default"
              />

              {/* <Button title={'ยืนยัน'} type={'focus'} onPress={() => this.goCreate()} /> */}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              width: "100%",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <Input.Search
              style={{
                width: "20vw",
                borderRadius: "10vw",
                paddingLeft: "1vw",
              }}
              placeholder={"ค้นหาข้อมูลทั้งหมดโดยไม่อิงตามวันที่"}
              value={fullTextSearch}
              onKeyPress={(e) => this.onKeyPressSearch(e, "fullTextSearch")}
              onChange={(e) => this.onChange(e.target.value, "fullTextSearch")}
            />
          </div>

          {/*###################### TABLE ######################*/}
          <Table
            title={() => <h2>รายงานการลงทะเบียน</h2>}
            columns={FocuscolumnTableWarranty((record) =>
              this.goDetail(record),
              (record) => this.createMessage(record),
              filters,
              (record) => this.confirmAppoveWarranty(record)
            )}
            rowClassName={(record, i) => (i % 2 === 1 ? "even" : "")}
            dataSource={data}
            bordered
            pagination={{ pageSize: 15 }}
            loading={isLoadingData}
            size="middle"
            scroll={{ x: 1500, y: '100%' }}
          />
        </div>
      </div>
    );
  }
}

export default WarrantyScene;
