import React from "react";
import ClaimScene from "../scene/Rizz/Claim/ClaimScene";
import RegisterWarranty from "../scene/Rizz/Warranty/RegisterWarranty";
import { BtnConfirm } from "../components/Button";
import { Tag } from "antd";
import {
  ColumnStatus,
  ColumnStatus_FOCUS,
  ColumnStatus_ImportAx_Focus,
  ColumnStore_type,
} from "../components/Table";
import DetailClaimScene from "../scene/Rizz/Claim/DetailClaimScene";
import EditClaimScene from "../scene/Rizz/Claim/EditClaimScene";
import CreateClaimScene from "../scene/Rizz/Claim/CreateClaimScene";
import HistoryScene from "../scene/Rizz/Claim/HistoryScene";
import FocusClaimScene from "../scene/Focus/Claim/ClaimScene";
import FocusActiveClaimScene from "../scene/Focus/Claim/activeClaimScene"
import FocusDetailClaimScene from "../scene/Focus/Claim/DetailClaimScene";
import FocusEditClaimScene from "../scene/Focus/Claim/EditClaimScene";
import FocusMultiClaimScene from "../scene/Focus/MultiClaim/MultiClaimScene";
import FocusRegisterWarrantyScene from "../scene/Focus/Warranty/RegisterWarranty";
import FocusCreateClaimScene from "../scene/Focus/Claim/CreateClaimScene";
import FocusHistoryScene from "../scene/Focus/Claim/HistoryScene";
import FocusWarrantyScene from "../scene/Focus/Warranty/WarrantyScene";
import FocusResetLineScene from "../scene/Focus/user/resetLineScene";
import EditWarrantyScene from "../scene/Focus/Warranty/EditWarrantyScene";
import ProductsScene from "../scene/Focus/Products/ProductsScene";
import ManageDealerScene from "../scene/Focus/Dealer/ManageDealerScene";
import { SendOutlined, EditOutlined, WechatOutlined, ScissorOutlined } from "@ant-design/icons";
import CustomerScene from "../scene/Focus/user/CustomerScene";
import GenQRCodeScene from "../scene/Focus/QRCode/GenQRCodeScene";
import CampaignIphoneScene from "../scene/Focus/Campaign/iphone/CampaignIphoneScene"

import { convertStrToFormat } from "../functions/functions";


export const menu = {
  rizz: {
    defaultUrl: "/rizz/claim",
    menu: [
      {
        path: "/rizz/register-warranty",
        name: "ลงทะเบียนสินค้า",
        icon: "form",
        component: RegisterWarranty,
      },
      {
        path: "/rizz/claim",
        name: "เคลมสินค้า",
        icon: "file-search",
        component: ClaimScene,
      },
      {
        path: "/rizz/history",
        name: "ประวัติการเคลม",
        icon: "profile",
        component: HistoryScene,
      },
    ],
    routes: [
      {
        index: 0,
        path: "/rizz/register-warranty",
        name: "ลงทะเบียนสินค้า",
        icon: "form",
        component: RegisterWarranty,
      },
      {
        index: 1,
        path: "/rizz/claim",
        name: "เคลมสินค้า",
        icon: "file-search",
        component: ClaimScene,
      },
      {
        path: "/rizz/claim_detail",
        name: "รายละเอียด",
        component: DetailClaimScene,
      },
      {
        path: "/rizz/billshipping",
      },
      {
        index: 2,
        path: "/rizz/history",
        name: "ประวัติการเคลม",
        component: HistoryScene,
      },
      {
        path: "/rizz/claim_edit",
        name: "แก้ไขรายการเคลมสินค้่า",
        component: EditClaimScene,
      },
      {
        path: "/rizz/claim_create",
        name: "ยืนเคลม",
        component: CreateClaimScene,
      },
    ],
    title: "RIZZ maximize ME moment",
  },

  admin: {
    defaultUrl: "focus/claim",
    menu: [
      {
        path: "/focus/register-warranty",
        name: "ลงทะเบียนสินค้า",
        icon: "form",
        component: FocusRegisterWarrantyScene,
      },
      {
        path: "/focus/claim",
        name: "เคลมสินค้า",
        icon: "file-search",
        component: FocusClaimScene,
      },
      {
        path: "/focus/multipost",
        name: "ยิงจ่ายพัสดุ",
        icon: "qrcode",
        component: FocusMultiClaimScene,
      },
      {
        path: "/focus/activeClaim",
        name: "ตัดรหัสประกัน",
        icon: "qrcode",
        component: FocusActiveClaimScene,
      },
      {
        path: "/focus/history",
        name: "ประวัติการเคลม",
        icon: "profile",
        component: FocusHistoryScene,
      },
      // , {
      //     path: '/focus/accounting',
      //     name: 'รายงานค่าจัดส่งสินค้า',
      //     icon: 'profile',
      //     component: FocusReportAccountingScene
      // }
      // , {
      //     path: '/focus/importax',
      //     name: 'Import to Ax',
      //     icon: 'profile',
      //     component: ImportAxScene
      // }
      {
        path: "/focus/warranty",
        name: "รายงานการลงทะเบียนสินค้า",
        icon: "profile",
        component: FocusWarrantyScene,
      },
      // {
      //   path: "/focus/reset-lineid",
      //   name: "รีเซ็ตไอดีไลน์ลูกค้า",
      //   icon: "form",
      //   component: FocusResetLineScene,
      // },
      {
        path: "/focus/customer",
        name: "ข้อมูลลูกค้า",
        icon: "form",
        component: CustomerScene,
      },
      {
        path: "/focus/dealer",
        name: "จัดการข้อมูลร้านค้า",
        icon: "form",
        component: ManageDealerScene,
      },
      {
        path: "/focus/campaign/iphone",
        name: "Campaign iPhone16",
        icon: "apple",
        component: CampaignIphoneScene,
      },
      {
        path: "/focus/gen-qrcode",
        name: "QRCODE รับประกัน",
        icon: "tags",
        component: GenQRCodeScene,
      },
      // {
      //   path: "/focus/products",
      //   name: "รายการสินค้ารับประกัน",
      //   icon: "form",
      //   component: ProductsScene,
      // },
    ],
    routes: [
      {
        index: 0,
        path: "/focus/register-warranty",
        name: "ลงทะเบียนสินค้า",
        component: FocusRegisterWarrantyScene,
      },
      {
        index: 1,
        path: "/focus/claim",
        name: "เคลมสินค้าFocus",
        component: FocusClaimScene,
      },
      {
        index: 2,
        path: "/focus/multipost",
        name: "ยิงจ่ายพัสดุ",
        component: FocusMultiClaimScene,
      },
      {
        index: 2,
        path: "/focus/activeClaim",
        name: "ตัดรหัสประกัน",
        component: FocusActiveClaimScene,
      },
      {
        index: 3,
        path: "/focus/history",
        name: "ประวัติการเคลม",
        component: FocusHistoryScene,
      },
      // , {
      //     index: 4,
      //     path: '/focus/accounting',
      //     name: 'รายงานค่าจัดส่งสินค้า',
      //     component: FocusReportAccountingScene

      // }
      // , {
      //     index: 5,
      //     path: '/focus/importax',
      //     name: 'Import to Ax',
      //     component: ImportAxScene
      // }
      {
        index: 6,
        path: "/focus/warranty",
        name: "รายงานการลงทะเบียนสินค้า",
        component: FocusWarrantyScene,
      },
      {
        path: "/focus/warranty_detail",
        name: "แก้ไขรับประกัน",
        component: EditWarrantyScene,
      },
      {
        path: "/focus/billshipping",
      },
      {
        path: "/focus/claim_detail",
        name: "รายละเอียด",
        component: FocusDetailClaimScene,
      },
      {
        path: "/focus/claim_edit",
        name: "แก้ไขรายการเคลมสินค้่า",
        component: FocusEditClaimScene,
      },
      {
        path: "/focus/claim_create",
        name: "ยื่นเคลม",
        component: FocusCreateClaimScene,
      },
      {
        path: "/focus/reset-lineid",
        name: "รีเซ็ตไอดีไลน์ลูกค้า",
        component: FocusResetLineScene,
      },
      {
        path: "/focus/products",
        name: "รายการสินค้ารับประกัน",
        component: ProductsScene,
      },
      {
        path: "/focus/customer",
        name: "ข้อมูลลูกค้า",
        component: CustomerScene,
      },
      {
        path: "/focus/dealer",
        name: "จัดการข้อมูลร้านค้า",
        component: ManageDealerScene,
      },
      {
        path: "/focus/campaign/iphone",
        name: "Campaign iPhone16",
        component: CampaignIphoneScene,
      },
      {
        path: "/focus/gen-qrcode",
        name: "QRCODE รับประกัน",
        component: GenQRCodeScene,
      },
    ],
    title: "Focus service",
    role: "shop",
  },

  shop: {
    defaultUrl: "focus/claim",
    menu: [
      {
        path: "/focus/register-warranty",
        name: "ลงทะเบียนสินค้า",
        icon: "form",
        component: FocusRegisterWarrantyScene,
      },
      {
        path: "/focus/claim",
        name: "เคลมสินค้า",
        icon: "file-search",
        component: FocusClaimScene,
      },
      {
        path: "/focus/history",
        name: "ประวัติการเคลม",
        icon: "profile",
        component: FocusHistoryScene,
      },
      {
        path: "/focus/warranty",
        name: "รายงานการลงทะเบียนสินค้า",
        icon: "profile",
        component: FocusWarrantyScene,
      },
      // {
      //   path: "/focus/reset-lineid",
      //   name: "รีเซ็ตไอดีไลน์ลูกค้า",
      //   icon: "form",
      //   component: FocusResetLineScene,
      // },
      {
        path: "/focus/customer",
        name: "ข้อมูลลูกค้า",
        icon: "form",
        component: CustomerScene,
      },
      {
        path: "/focus/dealer",
        name: "จัดการข้อมูลร้านค้า",
        icon: "form",
        component: ManageDealerScene,
      },
      {
        path: "/focus/campaign/iphone",
        name: "Campaign iPhone16",
        icon: "apple",
        component: CampaignIphoneScene,
      },
      {
        path: "/focus/gen-qrcode",
        name: "QRCODE รับประกัน",
        component: GenQRCodeScene,
      },
    ],
    routes: [
      {
        index: 0,
        path: "/focus/register-warranty",
        name: "ลงทะเบียนสินค้า",
        component: FocusRegisterWarrantyScene,
      },
      {
        index: 1,
        path: "/focus/claim",
        name: "เคลมสินค้าFocus",
        component: FocusClaimScene,
      },
      {
        index: 2,
        path: "/focus/history",
        name: "ประวัติการเคลม",
        component: FocusHistoryScene,
      },
      {
        index: 3,
        path: "/focus/warranty",
        name: "รายงานการลงทะเบียนสินค้า",
        component: FocusWarrantyScene,
      },
      {
        path: "/focus/warranty_detail",
        name: "แก้ไขรับประกัน",
        component: EditWarrantyScene,
      },
      {
        path: "/focus/billshipping",
      },
      {
        path: "/focus/claim_detail",
        name: "รายละเอียด",
        component: FocusDetailClaimScene,
      },
      {
        path: "/focus/claim_edit",
        name: "แก้ไขรายการเคลมสินค้่า",
        component: FocusEditClaimScene,
      },
      {
        path: "/focus/claim_create",
        name: "ยื่นเคลม",
        component: FocusCreateClaimScene,
      },
      {
        path: "/focus/reset-lineid",
        name: "รีเซ็ตไอดีไลน์ลูกค้า",
        component: FocusResetLineScene,
      },
      {
        path: "/focus/customer",
        name: "ข้อมูลลูกค้า",
        component: CustomerScene,
      },
      {
        path: "/focus/products",
        name: "รายการสินค้ารับประกัน",
        component: ProductsScene,
      },
      {
        path: "/focus/dealer",
        name: "จัดการข้อมูลร้านค้า",
        component: ManageDealerScene,
      },
      {
        path: "/focus/campaign/iphone",
        name: "Campaign iPhone16",
        icon: "form",
        component: CampaignIphoneScene,
      },
      {
        path: "/focus/gen-qrcode",
        name: "QRCODE รับประกัน",
        component: GenQRCodeScene,
      },
    ],
    title: "Focus service Shop",
    role: "shop",
  },
};

export const columnTableClaim = (fn = () => { }, onClick = () => { }) => [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
    width: "2vw",
    className: "td-content",
    onCell: (record, i) => ({
      onClick: () => fn(record),
    }),
    sorter: (a, b) => a.no - b.no,
  },
  {
    title: "เลขที่เคลม",
    dataIndex: "claim_id",
    align: "center",
    className: "td-content",
    width: "10vh",
    onCell: (record, i) => ({
      onClick: () => fn(record),
    }),
    sorter: (a, b) =>
      a.claim_id < b.claim_id ? -1 : a.claim_id > b.claim_id ? 1 : 0,
  },
  {
    title: "Line Name",
    dataIndex: "line_name",
    align: "center",
    className: "td-content",
    width: "8vw",
    onCell: (record, i) => ({
      onClick: () => fn(record),
    }),
  },
  {
    title: "ชื่อ-นามสกุล",
    dataIndex: "name",
    align: "center",
    className: "td-content",
    width: "9vw",
    onCell: (record, i) => ({
      onClick: () => fn(record),
    }),
  },
  {
    title: "เบอร์โทรศัพท์",
    dataIndex: "telephone",
    align: "center",
    className: "td-content",
    onCell: (record, i) => ({
      onClick: () => fn(record),
    }),
  },
  {
    title: "วันที่แจ้ง",
    dataIndex: "create_at",
    align: "center",
    width: "5vw",
    className: "td-content",
    onCell: (record, i) => ({
      onClick: () => fn(record),
    }),
    sorter: (a, b) =>
      a.create_at < b.create_at ? -1 : a.create_at > b.create_at ? 1 : 0,
  },
  {
    title: "รุ่นสินค้า",
    dataIndex: "product_name",
    align: "center",
    className: "td-content",
    width: "8vw",
    onCell: (record, i) => ({
      onClick: () => fn(record),
      style: {
        whiteSpace: "wrap",
        maxWidth: "8vw",
      },
    }),
    render: (text) => {
      return (
        <div style={{ fontSizeAdjust: 1 }}>
          <font className={"font-table"}>{text}</font>
        </div>
      );
    },
  },
  {
    title: "วันคงเหลือ",
    dataIndex: "time_warranty",
    align: "center",
    className: "td-content",
    width: "2vw",
    onCell: (record, i) => ({
      onClick: () => fn(record),
    }),
    sorter: (a, b) =>
      a.time_warranty < b.time_warranty
        ? -1
        : a.time_warranty > b.time_warranty
          ? 1
          : 0,
  },
  {
    title: "สถานะ",
    dataIndex: "status",
    align: "center",
    className: "td-content td-content-confirm",
    width: "7vw",
    render: (status) => <ColumnStatus status={status} />,
    onCell: (record, i) => ({
      onClick: () => fn(record),
    }),
    filters: [
      {
        text: "รอรับเรื่อง",
        value: 0,
      },
      {
        text: "ดำเนินการ",
        value: 1,
      },
      {
        text: "จัดส่งเรียบร้อย",
        value: 2,
      },
      {
        text: "ไม่อนุมัติ",
        value: 3,
      },
    ],
    onFilter: (value, record) => +record.status === +value,
  },
  {
    title: "Confirm EMS",
    dataIndex: "confirm_ems",
    align: "center",
    className: "td-content",
    width: "7vw",
    render: (text, record) =>
      text === "กรอกเลขพัสดุ" ? (
        <BtnConfirm title={text} onClick={() => onClick(record, 0)} />
      ) : (
        text
      ),
    onCell: (record, i) => ({
      onClick:
        record.shipping && record.shipping.ems
          ? () => onClick(record, 1)
          : null,
    }),
  },
  {
    title: "ส่งสินค้า",
    dataIndex: "shipping_date",
    align: "center",
    className: "td-content",
    width: "6vw",
    onCell: (record, i) => ({
      onClick: () => fn(record),
    }),
    sorter: (a, b) =>
      a.shipping_date < b.shipping_date
        ? -1
        : a.shipping_date > b.shipping_date
          ? 1
          : 0,
  },
  {
    title: "หมายเหตุ",
    dataIndex: "note",
    align: "center",
    className: "td-content",
    width: "7vw",
    onCell: (record, i) => ({
      onClick: () => fn(record),
    }),
  },
];
export const columnTableClaimFocus = (
  fn = () => { },
  onClick = () => { },
  onCreateMessage = () => { },
  onUactivate = () => { }
) => [
    {
      title: "ลำดับ",
      dataIndex: "no",
      align: "center",
      width: "2vw",
      className: "td-content",
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
      sorter: (a, b) => a.no - b.no,
    },
    {
      title: "เลขที่เคลม",
      dataIndex: "no_claim",
      align: "center",
      className: "td-content",
      width: "15vh",
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
      sorter: (a, b) =>
        a.no_claim < b.no_claim ? -1 : a.no_claim > b.no_claim ? 1 : 0,
    },

    {
      title: "ชื่อ-นามสกุล",
      dataIndex: "name",
      align: "center",
      className: "td-content",
      width: "10vw",
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
    },

    {
      title: "เบอร์โทรศัพท์",
      dataIndex: "tel",
      align: "center",
      className: "td-content",
      width: "6vw",
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
    },
    {
      title: "ชื่อไลน์",
      dataIndex: "lineName",
      align: "center",
      className: "td-content",
      width: "9vw",
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
    },
    {
      title: "วันที่แจ้ง",
      dataIndex: "dateclaim_formate",
      align: "center",
      width: "5vw",
      className: "td-content",
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
      sorter: (a, b) =>
        a.dateclaim < b.dateclaim ? -1 : a.dateclaim > b.dateclaim ? 1 : 0,
    },
    {
      title: "ชื่อสินค้า",
      dataIndex: "nameproduct",
      align: "center",
      className: "td-content",
      width: "15vw",
      onCell: (record, i) => ({
        onClick: () => fn(record),
        style: {
          whiteSpace: "wrap",
          maxWidth: "15vw",
        },
      }),
      // render: (text) => {
      //   return (
      //     <div style={{ fontSizeAdjust: 1 }}>
      //       <font className={"font-table"}>{text}</font>
      //     </div>
      //   );
      // },
    },
    // {
    //   title: "วันคงเหลือ",
    //   dataIndex: "time_warranty",
    //   align: "center",
    //   className: "td-content",
    //   width: "2vw",
    //   onCell: (record, i) => ({
    //     onClick: () => fn(record),
    //   }),
    //   sorter: (a, b) =>
    //     a.time_warranty < b.time_warranty
    //       ? -1
    //       : a.time_warranty > b.time_warranty
    //       ? 1
    //       : 0,
    // },
    {
      title: "สถานะ",
      dataIndex: "status",
      align: "center",
      className: "td-content td-content-confirm",
      width: "7vw",
      render: (status, record) => (
        <ColumnStatus_FOCUS status={status} type={record.type} />
      ),
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
      filters: [
        {
          text: "รอชำระเงิน",
          value: 1,
        },
        {
          text: "รอการตรวจสอบ",
          value: 2,
        },
        {
          text: "ชำระเงินสำเร็จ",
          value: 3,
        },
        {
          text: "จัดส่งเรียบร้อย",
          value: 4,
        },
        {
          text: "ไม่อนุมัติ",
          value: 99,
        },
      ],
      onFilter: (value, record) => +record.status === +value,
    },
    {
      title: "ประเภทเคลม",
      dataIndex: "type",
      align: "center",
      className: "td-content td-content-confirm",
      width: "7vw",
      render: (type) => <ColumnStore_type type={type} />,
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
      filters: [
        {
          value: "COMPANY",
          text: "DPLUS",
        },
        {
          value: "STORE",
          text: "ร้านค้าที่ร่วมรายการ",
        },
        // {
        //   value: "ONLINE",
        //   text: "Online",
        // },
        {
          value: "FOCUS",
          text: "โฟกัส shop",
        },
      ],
      onFilter: (value, record) => record.type === value,
    },
    {
      title: "เลขพัสดุ",
      dataIndex: "confirm_ems",
      align: "center",
      className: "td-content",
      width: "7vw",
      render: (text, record) =>
        record.type === "STORE" ? (
          ""
        ) : text === "กรอกเลขพัสดุ" ? (
          <BtnConfirm title={text} onClick={() => onClick(record, 0)} />
        ) : (
          text
        ),

      onCell: (record, i) => ({
        onClick: record.tracking ? () => onClick(record, 1) : null,
      }),
    },
    {
      title: "ส่งสินค้า",
      dataIndex: "shipping_formate",
      align: "center",
      className: "td-content",
      width: "6vw",
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
      sorter: (a, b) =>
        a.shipping_date < b.shipping_date
          ? -1
          : a.shipping_date > b.shipping_date
            ? 1
            : 0,
    },
    {
      title: "ปลดรหัสประกัน",
      dataIndex: "unactive",
      align: "center",
      className: "td-content",
      width: "7vw",
      render: (text, record) =>
        text === "รหัสประกัน" ? (
          <BtnConfirm title={text} onClick={() => onUactivate(record, 0)} />
        ) : (
          text
        ),
      onCell: (record, i) => ({
        onClick: record.unactive_code ? () => onUactivate(record, 1) : null,
      }),
    },
    {
      title: "ส่งข้อความ",
      dataIndex: "sendMessage",
      align: "center",
      className: "td-content",
      fixed: "right",
      width: "2vw",
      render: (text, record) => (
        <SendOutlined
          style={{ color: "green" }}
          onClick={() => onCreateMessage(record)}
        />
      ),
      onCell: (record, i) => ({
        onClick: () => onCreateMessage(record),
      }),
    },
    // {
    //   title: "เคลม Dealer",
    //   dataIndex: "document_id",
    //   align: "center",
    //   className: "td-content",
    //   width: "7vw",
    //   onCell: (record, i) => ({
    //     onClick: () => fn(record),
    //   }),
    // },

    // {
    //     title: 'หมายเหตุ',
    //     dataIndex: 'note',
    //     align: 'center',
    //     className: 'td-content',
    //     onCell: (record, i) => ({
    //         onClick: () => fn(record)
    //     }),
    //     render: note => <font style={{ fontSize: '0.6vw' }}>{note}</font>
    // },
  ];

export const columnTableAccounting = [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
    width: "2vw",
    className: "td-content",
    sorter: (a, b) => a.no - b.no,
  },
  {
    title: "วันที่โอน",
    dataIndex: "date_pay",
    align: "center",
    width: "5vw",
    className: "td-content",
    sorter: (a, b) =>
      a.dateclaim < b.date_pay ? -1 : a.date_pay > b.date_pay ? 1 : 0,
  },
  {
    title: "เลขที่เคลม",
    dataIndex: "no_claim",
    align: "center",
    className: "td-content",
    width: "10vh",
  },

  {
    title: "ชื่อ-นามสกุล",
    dataIndex: "name",
    align: "center",
    className: "td-content",
    width: "9vw",
  },
  {
    title: "เบอร์โทรศัพท์",
    dataIndex: "tel",
    align: "center",
    className: "td-content",
    width: "6vw",
  },
  {
    title: "จำนวนเงิน",
    dataIndex: "money_pay",
    align: "center",
    className: "td-content",
    width: "9vw",
  },
  {
    title: "รหัสสินค้า",
    dataIndex: "item_code",
    align: "center",
    className: "td-content",
    width: "9vw",
  },
];
export const columnTableImportAx = [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
    width: "2vw",
    className: "td-content",
    sorter: (a, b) => a.no - b.no,
  },
  {
    title: "วันที่แจ้งเคลม",
    dataIndex: "dateclaim_formate",
    align: "center",
    width: "5vw",
    className: "td-content",
    sorter: (a, b) =>
      a.dateclaim < b.dateclaim ? -1 : a.dateclaim > b.dateclaim ? 1 : 0,
  },
  {
    title: "ส่งสินค้า",
    dataIndex: "shipping_formate",
    align: "center",
    width: "6vw",
    className: "td-content",
    sorter: (a, b) =>
      a.dateclaim < b.dateclaim ? -1 : a.dateclaim > b.dateclaim ? 1 : 0,
  },
  {
    title: "เลขที่เคลม",
    dataIndex: "no_claim",
    align: "center",
    className: "td-content",
    width: "10vh",
  },
  {
    title: "ชื่อสินค้า",
    dataIndex: "nameproduct",
    align: "center",
    className: "td-content",
    width: "9vw",
  },
  {
    title: "ยี่ห้อมือถือ",
    dataIndex: "brandmobile",
    align: "center",
    className: "td-content",
    width: "9vw",
  },
  {
    title: "รุ่นมือถือ",
    dataIndex: "modelmobile",
    align: "center",
    className: "td-content",
    width: "9vw",
  },
  {
    title: "รหัสสินค้า",
    dataIndex: "item_code",
    align: "center",
    className: "td-content",
    width: "9vw",
  },
  {
    title: "เลขเอกสารจาก AX",
    dataIndex: "import_doc",
    align: "center",
    className: "td-content",
    width: "9vw",
  },
  {
    title: "สถานะการ Import",
    dataIndex: "import_status",
    align: "center",
    className: "td-content td-content-confirm",
    width: "7vw",
    render: (import_status) => (
      <ColumnStatus_ImportAx_Focus status={import_status} />
    ),

    filters: [
      {
        text: "รอการดำเนินการ",
        value: 99,
      },
      {
        text: "Importสำเร็จ",
        value: 1,
      },
      {
        text: "Importไม่สำเร็จ",
        value: 2,
      },
    ],
    onFilter: (value, record) => +record.import_status === +value,
  },
];

export const columnTableReport = [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
  },
  {
    title: "รายการสินค้า",
    dataIndex: "product_name",
    align: "center",
    width: "30%",
  },
  {
    title: "รูปสินค้า",
    dataIndex: "product_image",
    align: "center",
    render: (url) => (
      <img src={url} style={{ width: "2cm", height: "3cm" }} alt={""} />
    ),
  },
  {
    title: "จำนวน",
    dataIndex: "count",
    align: "center",
  },
];
export const columnTableReportFocus = [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
  },
  {
    title: "รายการสินค้า",
    dataIndex: "product_name",
    align: "center",
    width: "30%",
  },
  {
    title: "รูปสินค้า",
    dataIndex: "product_image",
    align: "center",
    render: (url) => (
      <img src={url} style={{ width: "2cm", height: "3cm" }} alt={""} />
    ),
  },
  {
    title: "จำนวน",
    dataIndex: "count",
    align: "center",
  },
];

export const columnTableHistory = [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
  },
  {
    title: "Line Name",
    dataIndex: "line_name",
    align: "center",
    // width: '30%'
  },
  {
    title: "ชื่อ-นามสกุล",
    dataIndex: "name",
    align: "center",
  },
  {
    title: "เบอร์โทร",
    dataIndex: "telephone",
    align: "center",
  },
  {
    title: "รหัสสินค้าลงทะเบียน",
    dataIndex: "product_register",
    align: "center",
    sorter: (a, b) =>
      a.product_register < b.product_register
        ? -1
        : a.product_register > b.product_register
          ? 1
          : 0,
  },
  {
    title: "ชื่อสินค้า",
    dataIndex: "product_name",
    align: "center",
  },
  {
    title: "จำนวนการเคลม",
    dataIndex: "count",
    align: "center",
    sorter: (a, b) => (a.count < b.count ? -1 : a.count > b.count ? 1 : 0),
  },
  {
    title: "วันที่เคลมล่าสุด",
    dataIndex: "last_date",
    align: "center",
    sorter: (a, b) =>
      a.last_date < b.last_date ? -1 : a.last_date > b.last_date ? 1 : 0,
  },
];

export const FocuscolumnTableHistory = [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
  },

  {
    title: "ชื่อ-นามสกุล",
    dataIndex: "name",
    align: "center",
  },
  {
    title: "เบอร์โทร",
    dataIndex: "telephone",
    align: "center",
  },

  {
    title: "จำนวนการเคลม",
    dataIndex: "count",
    align: "center",
    sorter: (a, b) => (a.count < b.count ? -1 : a.count > b.count ? 1 : 0),
  },
  {
    title: "วันที่เคลมล่าสุด",
    dataIndex: "last_date",
    align: "center",
    sorter: (a, b) =>
      a.last_date < b.last_date ? -1 : a.last_date > b.last_date ? 1 : 0,
  },
];
export const FocuscolumnTableWarranty = (
  fn = () => { },
  onCreateMessage = () => { },
  filters, confirmAppoveWarranty = () => { }) => [
    {
      title: "ลำดับ",
      dataIndex: "no",
      align: "center",
      width: 50,
      onCell: (data, i) => ({
        onClick: () => fn(data),
      }),
    },

    {
      title: "เลขที่ประกัน",
      dataIndex: "no_warranty",
      align: "center",
      width: 80,
      onCell: (data, i) => ({
        onClick: () => fn(data),
      }),
    },
    {
      title: "ชื่อ-นามสกุล",
      dataIndex: "name",
      align: "center",
      width: 200,
      onCell: (data, i) => ({
        onClick: () => fn(data),
      })
    },
    {
      title: "ชื่อสินค้า",
      dataIndex: "nameproduct",
      align: "center",
      width: 200,
      filters: filters.products,
      onFilter: (value, record) => record.nameproduct === value,
    },
    {
      title: "รหัสรับประกัน",
      dataIndex: "decode",
      align: "center",
      width: 120,
      onCell: (data, i) => ({
        onClick: () => fn(data),
      }),
    },
    {
      title: "แบรนด์",
      dataIndex: "brandmobile",
      align: "center",
      width: 80,
      onCell: (data, i) => ({
        onClick: () => fn(data),
      }),
    },
    {
      title: "รุ่น",
      dataIndex: "modelmobile",
      align: "center",
      width: 100,
      filters: filters.modelMobiles,
      onFilter: (value, record) => record.modelmobile === value,
    },
    {
      title: "ช่องทางการซื้อ",
      dataIndex: "namestore",
      align: "center",
      width: 120,
      filters: filters.channelBuy,
      onFilter: (value, record) => record.namestore === value,
    },
    {
      title: "สถานะ",
      dataIndex: "status",
      align: "center",
      width: 100,
      render: (status, record) => (
        <ColumnStatus_FOCUS status={status} type={"COMPANY"} />
      ),
      filters: [
        {
          text: "ลงทะเบียน",
          value: 0,
        },
        {
          text: "รอชำระเงิน",
          value: 1,
        },
        {
          text: "รอการตรวจสอบ",
          value: 2,
        },
        {
          text: "ชำระเงินสำเร็จ",
          value: 3,
        },
        {
          text: "จัดส่งเรียบร้อย",
          value: 4,
        },
        {
          text: "ไม่อนุมัติ",
          value: 99,
        },
      ],
      onFilter: (value, record) => +record.status === +value,
    },

    {
      title: "เบอร์โทร",
      dataIndex: "telephone",
      align: "center",
      width: 100,
      onCell: (data, i) => ({
        onClick: () => fn(data),
      }),
    },
    {
      title: "วันที่ลงทะเบียน",
      dataIndex: "create",
      align: "center",
      width: 100,
      onCell: (data, i) => ({
        onClick: () => fn(data),
      }),
      sorter: (a, b) => (a.create < b.create ? -1 : a.create > b.create ? 1 : 0),
    },
    {
      title: "วันหมดอายุ",
      dataIndex: "dateexp",
      align: "center",
      width: 100,
      onCell: (data, i) => ({
        onClick: () => fn(data),
      }),
      sorter: (a, b) => (a.create < b.create ? -1 : a.create > b.create ? 1 : 0),
    },
    {
      title: "วันคงเหลือ",
      dataIndex: "time_warranty",
      align: "center",
      width: 80,
      onCell: (data, i) => ({
        onClick: () => fn(data),
      }),
      sorter: (a, b) => (a.create < b.create ? -1 : a.create > b.create ? 1 : 0),
    },
    {
      title: "line Id",
      dataIndex: "line_id",
      align: "center",
      width: 300,
      onCell: (data, i) => ({
        onClick: () => fn(data),
      }),
    },
    {
      title: "ส่งข้อความ",
      dataIndex: "sendMessage",
      align: "center",
      className: "td-content",
      fixed: "right",
      width: 80,
      render: (text, record) => (
        <SendOutlined
          style={{ color: "green" }}
          onClick={() => onCreateMessage(record)}
        />
      ),
      onCell: (record, i) => ({
        onClick: () => onCreateMessage(record),
      }),
    },
    {
      title: "ตัดรับประกัน",
      dataIndex: "",
      align: "center",
      className: "td-content",
      fixed: "right",
      width: 80,
      render: (text, record) => (
        <ScissorOutlined onClick={() => confirmAppoveWarranty(record.no_warranty)} />
      ),
    },
  ];


export const UserColumnTable = (
  onReset = () => { },
  onEdit = () => { }
) => [
    {
      title: "รหัสลูกค้า",
      dataIndex: "user_id",
      align: "center"
    },
    {
      title: "ชื่อลูกค้า",
      dataIndex: "name",
    },
    {
      title: "เบอร์โทร",
      dataIndex: "telephone",
    },
    {
      title: "ไลน์",
      dataIndex: "line_id",
      align: "center",
      render: (text, record) => (
        <WechatOutlined
          style={{ color: text && "green" }}
          onClick={() => onReset(record)}
          title={text ? "OTP แล้ว" : "ยังไม่ได้ OTP"}
        />
      ),
    },
    {
      title: "แก้ไข",
      dataIndex: "",
      align: "center",
      render: (text, record) => (
        <EditOutlined
          onClick={() => onEdit(record)}
        />
      ),
    }
  ]

export const KEY_BILL = "KqkTxGXzw1";

export const STATUS_CLAIM = [
  {
    status_id: 0,
    status_name: "รอรับเรื่อง",
  },
  {
    status_id: 1,
    status_name: "กำลังดำเนินการ",
  },
  {
    status_id: 2,
    status_name: "จัดส่งเรียบร้อย",
  },
  {
    status_id: 99,
    status_name: "ไม่อนุมัติ",
  },
];
export const STATUS_CLAIM_FOCUS = [
  {
    status_id: 0,
    status_name: "ลงทะเบียน",
  },
  {
    status_id: 1,
    status_name: "รอชำระเงิน",
  },
  {
    status_id: 2,
    status_name: "รอการตรวจสอบ",
  },
  {
    status_id: 3,
    status_name: "ชำระเงินเรียบร้อย",
  },
  {
    status_id: 4,
    status_name: "จัดส่งเรียบร้อย",
  },
  {
    status_id: 99,
    status_name: "ไม่อนุมัติ",
  },
];
export const STATUS_CLAIM_DEALER = [
  {
    status_id: 2,
    status_name: "รอรับบริการ",
  },
  {
    status_id: 3,
    status_name: "เคลมเสร็จเรียบร้อย",
  },
  {
    status_id: 4,
    status_name: "จัดส่งเรียบร้อย",
  },
];
export const STORE_FOCUS = [
  {
    original_name: "COMPANY",
    thai_name: "DPLUS",
  },
  {
    original_name: "STORE",
    thai_name: "ร้านค้าที่ร่วมรายการ",
  },
  {
    original_name: "ONLINE",
    thai_name: "Online",
  },
  {
    original_name: "FOCUS",
    thai_name: "โฟกัส shop",
  },
];
export const STATUS_IMPORTAX_FOCUS = [
  {
    status_id: 99,
    status_name: "รอการดำเนินการ",
  },
  {
    status_id: 1,
    status_name: "Importเข้าเรียบร้อย",
  },
  {
    status_id: 2,
    status_name: "Importไม่สำเร็จ",
  },
];

export const STATUS_STORE_CLAIM = [
  {
    status_id: 0,
    status_name: "COMPANY",
  },
  {
    status_id: 1,
    status_name: "STORE",
  },
  {
    status_id: 2,
    status_name: "",
  },
];
export const CUSTOMER_WARRANTY_FORM = [
  {
    fieldName: "tel",
    label: "เบอร์โทรศัพท์ :",
    type: "tel",
    value: "",
  },
  {
    fieldName: "fullname",
    label: "ชื่อ-นามสกุล :",
    type: "text",
    value: "",
  },
];
export const PRODUCTS_WARRANTY_FORM = [
  {
    fieldName: "qrcode",
    label: "รหัสรับประกันสินค้า :",
    type: "text",
    value: "",
  },
  {
    fieldName: "nameproduct",
    label: "ชื่อสินค้า :",
    type: "select",
    value: "",
  },
  {
    fieldName: "brandmobile",
    label: "ยี่ห้อมือถือ :",
    type: "select",
    value: "",
  },
  {
    fieldName: "modelmobile",
    label: "รุ่นมือถือ :",
    type: "select",
    value: "",
  },
  {
    fieldName: "type_store",
    label: "ช่องทางการซื้อ :",
    type: "select",
    value: "",
  },
  // {
  //   fieldName: 'store_no',
  //   label: 'รหัสร้านค้า :',
  //   type:'text',
  //   value:''
  // },
  // {
  //   fieldName: 'store_name',
  //   label: 'ชื่อร้านค้า :',
  //   type:'text',
  //   value:''
  // },
];

export const FocuscolumnTableProducts = [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
  },

  {
    title: "ชื่อสินค้า",
    dataIndex: "product_name",
    align: "left",
  },
  {
    title: "หมวดหมู่",
    dataIndex: "category",
    align: "center",
  },
  {
    title: "สถานะ",
    dataIndex: "product_status",
    align: "center",
    render: (product_status) => {
      return (
        <Tag color={product_status === "1" ? "#52c41a" : "#f5222d"}>
          {product_status === "1" ? "Active" : "noneActive"}
        </Tag>
      );
    },
  },
  {
    title: "",
    dataIndex: "edit_product",
    align: "center",
  },
];

export const FocusColumnTableDealer = [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
  },
  {
    title: "QR ร้านค้า",
    dataIndex: "qrStore",
    align: "center",
  },
  {
    title: "รหัสร้านค้า",
    dataIndex: "store_no",
    align: "center",
  },
  {
    title: "ชื่อร้านค้า",
    dataIndex: "store_name",
    align: "center",
  },
  {
    title: "เบอร์โทร",
    dataIndex: "store_tel",
    align: "center",
  },
  {
    title: "สาขา",
    dataIndex: "branch_name",
    align: "center",
  },
  {
    title: "รหัส Sale",
    dataIndex: "sale_no",
    align: "center",
  },
  {
    title: "ประเภท",
    dataIndex: "type_store",
    align: "center",
    filters: [
      {
        text: "FOCUS",
        value: "FOCUS",
      },
      {
        text: "STORE",
        value: "STORE",
      },
    ],
    onFilter: (value, record) => record.type_store === value,
  },
  {
    title: "สถานะ",
    dataIndex: "store_status",
    align: "center",
    render: (store_status) => {
      return (
        <Tag color={store_status == "1" ? "#52c41a" : "#f5222d"}>
          {store_status == "1" ? "Active" : "Inactive"}
        </Tag>
      );
    },
    filters: [
      {
        text: "Inactive",
        value: 0,
      },
      {
        text: "Active",
        value: 1,
      },
    ],
    onFilter: (value, record) => +record.store_status === +value,
  },
  {
    title: "",
    dataIndex: "action",
    align: "center",
  },
];


export const ColumnQRCode = () => [
  {
    title: "ลำดับ",
    dataIndex: "key",
    width: "5%",
    align: "center",
  },
  {
    title: "หมวดหมู่",
    dataIndex: "category",
    width: "25%",
    align: "left",
  },
  {
    title: "จำนวนทั้งหมด",
    dataIndex: "total",
    width: "10%",
    align: "right",
    render: (text, record_) => {
      return convertStrToFormat(text, 'money')
    }
  },
  {
    title: "จำนวนที่ใช้ไป",
    dataIndex: "used",
    width: "10%",
    align: "right",
    render: (text, record_) => {
      return text ? convertStrToFormat(text, 'money') : text
    }
  },
  {
    title: "จำนวนคงเหลือ",
    dataIndex: "balance",
    width: "10%",
    align: "right",
    render: (text, record_) => {
      return text ? convertStrToFormat(text, 'money') : text
    }
  }
]


export const CampaignIphoneColumnTableWarranty = (filters) => [
    {
      title: "ลำดับ",
      dataIndex: "no",
      align: "center",
      width: 60,
    },
    {
      title: "วันที่ลงทะเบียน",
      dataIndex: "createdDateTime",
      align: "left",
      width: 150,
      sorter: (a, b) => (a.createdDateTime < b.createdDateTime ? -1 : a.createdDateTime > b.createdDateTime ? 1 : 0),
    },
    {
      title: "ชื่อ-นามสกุล",
      dataIndex: "fullName",
      align: "left",
      width: 200,
      sorter: (a, b) => (a.fullName < b.fullName ? -1 : a.fullName > b.fullName ? 1 : 0),
    },
    {
      title: "เบอร์โทรศัพท์",
      dataIndex: "phoneNumber",
      align: "left",
      width: 140,
      sorter: (a, b) => (a.phoneNumber < b.phoneNumber ? -1 : a.phoneNumber > b.phoneNumber ? 1 : 0),
    },
    {
      title: "ช่องทางการสั่งซื้อ",
      dataIndex: "pruchaseChannelName",
      align: "left",
      width: 160,
      sorter: (a, b) => (a.pruchaseChannelName < b.pruchaseChannelName ? -1 : a.pruchaseChannelName > b.pruchaseChannelName ? 1 : 0),
    },
    {
      title: "รหัสร้านค้า",
      dataIndex: "storeCode",
      align: "left",
      width: 150,
      sorter: (a, b) => (a.storeCode < b.storeCode ? -1 : a.storeCode > b.storeCode ? 1 : 0),
        },
    {
      title: "ร้านค้า",
      dataIndex: "storeName",
      align: "left",
      width: 250,
      sorter: (a, b) => (a.storeName < b.storeName ? -1 : a.storeName > b.storeName ? 1 : 0),
    },
    {
      title: "ประเภทสินค้า",
      dataIndex: "productCategoryName",
      align: "left",
      width: 250,
      sorter: (a, b) => (a.productCategoryName < b.productCategoryName ? -1 : a.productCategoryName > b.productCategoryName ? 1 : 0),
    },
    {
      title: "รุ่นมือถือ",
      dataIndex: "modelName",
      align: "left",
      width: 160,
      sorter: (a, b) => (a.modelName < b.modelName ? -1 : a.modelName > b.modelName ? 1 : 0),
    },
   
  ];
